import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      Powered by{" "}
      <a
        className="footer-link"
        href="https://legitcheck.io"
        target="_blank"
        rel="noreferrer"
      >
        Legitcheck.io
      </a>
    </div>
  );
}
