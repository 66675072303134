import { AxiosError } from "axios";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import invalidIcon from "../../assets/invalid.png";
import logo1 from "../../assets/logo-1.png";
import validIcon from "../../assets/valid.png";
import API from "../../util/API";
import "./validation.css";

interface ProductInfo {
  id?: string;
  batchId?: string;
  timesViewed?: number;
  isActive: boolean;
  isCompromised?: boolean;
}

interface ShootersProductInfo extends ProductInfo {
  sku?: string;
  shootersBatchNumber?: string;
  manufacturer?: string;
  productType?: string;
  cannabinoidsPercent?: string;
  terpenesPercent?: string;
  thcPercent?: string;
  cbdPercent?: string;
  mfgDate?: string;
  expDate?: string;
  packagedBy?: string;
}

interface ProductViewProps {
  productInfo: ShootersProductInfo;
}

interface InvalidProductViewProps {
  id?: string;
}

const ValidationPageContainer = (props: PropsWithChildren) => {
  return (
    <div className="validation-page-container">
      <div className="mb-4">
        <Image
          className="d-block m-auto"
          src={logo1}
          width="250x"
          height="120px"
        ></Image>
      </div>
      <Card className="landing-page-card shadow-lg p-3">{props.children}</Card>
    </div>
  );
};

const RetrievingProductInfoView = () => {
  return (
    <div className="h-100 w-100 text-center m-auto">
      <h3 className="p-4">Retrieving Product Info...</h3>
      <i className="mb-4 fas fa-circle-notch fa-spin fa-2x"></i>
    </div>
  );
};

const ProductValidationErrorView = () => {
  return (
    <div className="w-90 m-auto text-center py-4">
      <h3 className="mb-3">Error Validating Product</h3>
      <div>
        We're sorry, but your product couldn't be validated at this time. Please
        try again in a few minutes.
      </div>
    </div>
  );
};

const PlaceholderProductView = ({ productInfo }: ProductViewProps) => {
  return (
    <div className="w-90 m-auto my-3">
      <h4 className="text-success mb-4 text-center">
        YOUR PRODUCT IS AUTHENTIC
      </h4>
      <Image className="validation-page-icon" src={validIcon}></Image>
      <h5>Serial Number: {productInfo.id}</h5>

      <hr />

      <div>
        <h5>Product Profile</h5>
        <div>Product Type: {productInfo.productType}</div>
        <div>Cannabinoids: {productInfo.cannabinoidsPercent}</div>
        <div>Terpenes: {productInfo.terpenesPercent}</div>
        <div>THC: {productInfo.thcPercent}</div>
        <div>CBD: {productInfo.cbdPercent}</div>
        <div>Packaged By: {productInfo.packagedBy}</div>
      </div>

      {productInfo?.timesViewed && productInfo?.timesViewed > 1 && (
        <h4 className="mt-4 mb-2 text-center">
          Your QR has been scanned multiple times
        </h4>
      )}
      <div className="mt-3 text-center">
        Scan Count: {productInfo.timesViewed}
      </div>
    </div>
  );
};

const AuthenticProductView = ({ productInfo }: ProductViewProps) => {
  return (
    <div className="w-90 my-3 mx-4">
      <h4 className="text-success mb-4 text-center">
        YOUR PRODUCT IS AUTHENTIC
      </h4>
      <Image className="validation-page-icon" src={validIcon}></Image>
      <h5>Serial Number: {productInfo.id}</h5>
      <h5>Batch: {productInfo.shootersBatchNumber}</h5>
      <h5>SKU: {productInfo.sku}</h5>

      <hr />

      <div>
        <h5>Product Profile</h5>
        <div>Product Type: {productInfo.productType}</div>
        <div>
          {`Manufacture Date: ${
            productInfo.mfgDate &&
            new Date(productInfo.mfgDate).toLocaleDateString()
          }`}
        </div>
        <div>
          {`Expiration Date: ${
            productInfo.expDate &&
            new Date(productInfo.expDate).toLocaleDateString()
          }`}
        </div>
        <div>Cannabinoids: {productInfo.cannabinoidsPercent}</div>
        <div>Terpenes: {productInfo.terpenesPercent}</div>
        <div>THC: {productInfo.thcPercent}</div>
        <div>CBD: {productInfo.cbdPercent}</div>
        <div>Packaged By: {productInfo.packagedBy}</div>
      </div>

      {productInfo?.timesViewed && productInfo?.timesViewed > 1 && (
        <h3 className="my-3 text-center">
          Your QR has been scanned multiple times
        </h3>
      )}
      <div className="mt-3 text-center">
        Scan Count: {productInfo.timesViewed}
      </div>
    </div>
  );
};

const NotAuthenticProductView = ({ id }: InvalidProductViewProps) => {
  return (
    <>
      <h3 className="m-2 p-2 text-center">Product Test Report</h3>

      <div className="w-90 m-auto text-center">
        <h4 className="mb-4" style={{ color: "#ee116f" }}>
          YOUR PRODUCT MAY NOT BE AUTHENTIC
        </h4>
        <Image
          className="d-block m-auto validation-page-icon"
          src={invalidIcon}
        ></Image>
        <div className="mt-3">Serial Number: {id}</div>
      </div>

      <hr className="my-3" />

      <div className="w-90 m-auto text-center">
        <span>This serial number has been scanned more than five times.</span>
      </div>
    </>
  );
};

const InvalidProductView = () => {
  return (
    <div className="h-100 w-100 text-center m-auto my-3">
      <h4 className="p4 mb-3 text-center">Invalid Product Key</h4>
      <h6 className="text-center">
        The key linked to this QR code is invalid, which means this product
        can't be verified.
      </h6>
    </div>
  );
};

const CompromisedProductView = () => {
  return (
    <div className="w-90 m-auto my-3">
      <h4 className="mb-3 text-center">This code has been compromised</h4>
      <Image
        className="d-block m-auto validation-page-icon"
        src={invalidIcon}
      ></Image>
      <h6 className="text-center mt-3">
        This code has been deemed as compromised. Someone has faked our stickers
        and is using it on product that is not authentic.
      </h6>
    </div>
  );
};

export default function ValidationPage() {
  const { id } = useParams();

  const [productInfo, setProductInfo] = useState({
    id: id,
    sku: "",
    shootersBatchNumber: "",
    manufacturer: "",
    productType: "",
    cannabinoidsPercent: "",
    terpenesPercent: "",
    thcPercent: "",
    cbdPercent: "",
    mfgDate: "",
    expDate: "",
    packagedBy: "",
    isActive: true,
  });
  const [loadingValidation, setLoadingValidation] = useState(true);
  const [errorValidatingProduct, setErrorValidatingProduct] = useState(false);
  const [productIdIsInvalid, setProductIdIsInvalid] = useState(false);
  const [productIsAuthentic, setProductIsAuthentic] = useState(false);
  const [productHasNoBatch, setProductHasNoBatch] = useState(false);
  const [productIsCompromised, setProductIsCompromised] = useState(false);

  /**
   * Validation Flow:
   * 1. EntryID not found - set productIsAuthentic(false), show InvalidProductView
   * 2. isCompromised = true - set productIsCompromised(true), show CompromisedProductView
   * 3. Product with batch
   *  -- Active - setProductIsAuthentic(true), show AuthenticProductView
   *  -- Inactive - setProductIsAuthentic(false), show NotAuthenticProductView
   * 4. Product w/o batch
   *  -- Scans <= 5 - setProductIsAuthentic(true), show PlaceholderProductView
   *  -- Scans > 5 - setProductIsAuthentic(false), show NotAuthenticProductView
   */

  const validateProductById = useCallback(async () => {
    try {
      const result = await API.get(`validate?id=${id}`);
      if (result && result.status === 200 && result.data) {
        const product = result.data;
        if (product.isCompromised === true) {
          setProductIsCompromised(true);
        } else if (product.batchId === null) {
          // Valid code, missing batch entry
          if (product.timesViewed <= 5) {
            const defaultInfoWithTimesViewed = {
              ...productInfo,
              timesViewed: product.timesViewed,
            };
            setProductInfo(defaultInfoWithTimesViewed);
            setProductIsAuthentic(true);
            setProductHasNoBatch(true);
          }
        } else {
          // Valid code and batch entry
          if (product.isActive) {
            setProductInfo(product);
            setProductIsAuthentic(true);
          }
        }
      } else if (result && result.status === 204) {
        setProductIdIsInvalid(true);
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.status === 400) {
        setProductIdIsInvalid(true);
      } else {
        setErrorValidatingProduct(true);
      }
    } finally {
      setLoadingValidation(false);
    }
  }, [id, productInfo]);

  useEffect(() => {
    if (!id) return;
    if (/^[0-9A-Za-z-_]{11}$/.test(id)) {
      validateProductById();
    } else {
      setProductIdIsInvalid(true);
      setLoadingValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ValidationPageContainer>
      {loadingValidation ? (
        <RetrievingProductInfoView />
      ) : productIdIsInvalid ? (
        <InvalidProductView />
      ) : errorValidatingProduct ? (
        <ProductValidationErrorView />
      ) : productIsCompromised ? (
        <CompromisedProductView />
      ) : !productIsAuthentic ? (
        <NotAuthenticProductView id={id} />
      ) : productHasNoBatch ? (
        <PlaceholderProductView productInfo={productInfo} />
      ) : (
        <AuthenticProductView productInfo={productInfo} />
      )}
    </ValidationPageContainer>
  );
}
