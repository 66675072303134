import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/footer";
import ValidationPage from "./pages/validation/validation";
//import MaintenancePage from "./pages/Maintenance/Maintenance.js";

export default function App() {
  return (
    <>
      <div className="appContainer">
        <Routes>
          <Route path="/validate/:id" element={<ValidationPage />} />
          {/*<Route path="/" element={<MaintenancePage />} />*/}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}
